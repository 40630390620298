<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <user-list-financial
      ref="userListFinancial"
      :manager-id="managerId"
      :payment-date-from="paymentDateFrom"
      :payment-date-to="paymentDateTo"
      :recurrent="recurrent"
    />
  </section>
</template>

<script>
import {
  BBreadcrumb,
} from 'bootstrap-vue'
import UserListFinancial from '../components/UserListFinancial.vue'

export default {
  components: {
    BBreadcrumb,
    UserListFinancial,
  },

  props: {
    managerIdFromProps: {
      type: Number,
      default: null,
    },
    paymentDateFrom: {
      type: String,
      default: null,
    },
    paymentDateTo: {
      type: String,
      default: null,
    },
    recurrent: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Vendas',
          active: true,
        },
      ],
      userData: null,
      managerId: null,
    }
  },

  created() {
    const userDataString = localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    if (this.userData.permission_type !== 'root') {
      this.managerId = this.userData.id
    } else if (this.managerIdFromProps) {
      this.managerId = this.managerIdFromProps
    }
  },
}
</script>
